import React from "react";

const StatsItem = ({stat, emphasis, rest}) => (
  <p>
    <span className="block text-2xl font-bold text-white">{stat}</span>
    <span className="mt-1 block text-base text-gray-300">
      <span className="font-medium text-white">{emphasis}</span>{" "}
      {rest}
    </span>
  </p>
);

export default StatsItem;
